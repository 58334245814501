export const services = [
  {
    image: "recruitment.png",
    title: "Recruitment",
    content: {
      __html:
        "We eliminate bias by matching your tech vacancies to the <b>RIGHT</b> candidates, replacing guesswork with skill-based showcasing.",
    },
  },
  {
    image: "outsourcing.png",
    title: "Outsourcing",
    content: {
      __html:
        "You can now <b>FOCUS</b> on your deliverables while we set up the project team to deliver on time and to your specification.",
    },
  },
  {
    image: "placement.png",
    title: "Placement",
    content: {
      __html:
        "Take your career to the next level. Work on big projects, and join blue-chip companies. Let's <b>PITCH</b> you to the world.",
    },
  },
];
