import { Route, Routes } from "react-router-dom";
import "./App.css";
import HireTalent from "./Components/HireTalent/HireTalent";
import PrivacyPolicy from "./Components/TermsOfUse/PrivacyPolicy";
import TermsOfUse from "./Components/TermsOfUse/TermsOfUse";
import HomePage from "./Pages/Homepage/HomePage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route index path="/" element={<HomePage />} />
        <Route path="hire-talent" element={<HireTalent />} />
        <Route path="terms-of-use" element={<TermsOfUse />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </div>
  );
}

export default App;
