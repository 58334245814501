export const brands = [
  "airtel.png",
  "flutterwave.png",
  "google.png",
  "huawei.png",
  "mtn.png",
  "oracle.png",
  "visa.png",
  "shell.png",
  "zenith.png",
  "white.jpg",
];
