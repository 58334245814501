import React, { useState } from "react";
import { brands } from "./shared";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const Stats = () => {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  return (
    <div className=" bg-stats mt-[75px] bg-no-repeat bg-cover bg-left-top">
      <div className="container md:flex md:justify-center">
        <div className=" flex flex-col w-[934px]  pt-24 pb-20  mobile:hidden">
          <div className="flex justify-between mb-[70px]  ">
            <div className="flex flex-col items-center">
              <CountUp start={viewPortEntered ? null : 0} end={16} suffix="+">
                {({ countUpRef }) => (
                  <VisibilitySensor
                    active={!viewPortEntered}
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setViewPortEntered(true);
                      }
                    }}
                    delayedCall
                  >
                    <h1 className="stats-value" ref={countUpRef}>
                      0
                    </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <p className="stats-title">Years and counting</p>
            </div>
            <div className="flex flex-col items-center">
              <CountUp start={viewPortEntered ? null : 0} end={17} suffix="+">
                {({ countUpRef }) => (
                  <VisibilitySensor
                    active={!viewPortEntered}
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setViewPortEntered(true);
                      }
                    }}
                    delayedCall
                  >
                    <h1 className="stats-value" ref={countUpRef}>
                      0
                    </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <p className="stats-title">Roles</p>
            </div>
            <div className="flex flex-col items-center">
              <CountUp start={viewPortEntered ? null : 0} end={50} suffix="+">
                {({ countUpRef }) => (
                  <VisibilitySensor
                    active={!viewPortEntered}
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setViewPortEntered(true);
                      }
                    }}
                    delayedCall
                  >
                    <h1 className="stats-value" ref={countUpRef}>
                      0
                    </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <p className="stats-title">Organisations</p>
            </div>
          </div>
          <hr className="mb-[70px]" />
          <div className="flex justify-between ">
            <div className="flex flex-col items-center">
              <CountUp start={viewPortEntered ? null : 0} end={1100} suffix="+">
                {({ countUpRef }) => (
                  <VisibilitySensor
                    active={!viewPortEntered}
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setViewPortEntered(true);
                      }
                    }}
                    delayedCall
                  >
                    <h1 className="stats-value" ref={countUpRef}>
                      0
                    </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <p className="stats-title">Projects</p>
            </div>
            <div className="flex flex-col items-center">
              <div>
                <span className="text-[50px] text-white font-font1 font-semibold inline-block">
                  $
                </span>
                <CountUp
                  start={viewPortEntered ? null : 0}
                  end={100}
                  suffix="m+"
                >
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      active={!viewPortEntered}
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setViewPortEntered(true);
                        }
                      }}
                      delayedCall
                    >
                      <h1 className="stats-value inline-block" ref={countUpRef}>
                        0
                      </h1>
                    </VisibilitySensor>
                  )}
                </CountUp>
              </div>
              <p className="stats-title">Project valuation</p>
            </div>
            <div className="flex flex-col items-center">
              <CountUp start={viewPortEntered ? null : 0} end={13} suffix="+">
                {({ countUpRef }) => (
                  <VisibilitySensor
                    active={!viewPortEntered}
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setViewPortEntered(true);
                      }
                    }}
                    delayedCall
                  >
                    <h1 className="stats-value" ref={countUpRef}>
                      0
                    </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <p className="stats-title">Countries</p>
            </div>
          </div>
        </div>

        <div className="md:hidden flex flex-col pt-12">
          <div className="mb-9 flex justify-between flex-wrap">
            <div className="flex flex-col items-center">
              <CountUp start={viewPortEntered ? null : 0} end={16} suffix="+">
                {({ countUpRef }) => (
                  <VisibilitySensor
                    active={!viewPortEntered}
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setViewPortEntered(true);
                      }
                    }}
                    delayedCall
                  >
                    <h1 className="stats-value" ref={countUpRef}>
                      0
                    </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <p className="stats-title">Years and counting</p>
            </div>
            <div className="flex flex-col items-center">
              <CountUp start={viewPortEntered ? null : 0} end={17} suffix="+">
                {({ countUpRef }) => (
                  <VisibilitySensor
                    active={!viewPortEntered}
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setViewPortEntered(true);
                      }
                    }}
                    delayedCall
                  >
                    <h1 className="stats-value" ref={countUpRef}>
                      0
                    </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <p className="stats-title">Roles</p>
            </div>
          </div>
          <hr className="mb-9" />
          <div className="mb-9 flex justify-between flex-wrap">
            <div className="flex flex-col items-center">
              <CountUp start={viewPortEntered ? null : 0} end={50} suffix="+">
                {({ countUpRef }) => (
                  <VisibilitySensor
                    active={!viewPortEntered}
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setViewPortEntered(true);
                      }
                    }}
                    delayedCall
                  >
                    <h1 className="stats-value" ref={countUpRef}>
                      0
                    </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <p className="stats-title">Organisations</p>
            </div>
            <div className="flex flex-col items-center">
              <CountUp start={viewPortEntered ? null : 0} end={1100} suffix="+">
                {({ countUpRef }) => (
                  <VisibilitySensor
                    active={!viewPortEntered}
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setViewPortEntered(true);
                      }
                    }}
                    delayedCall
                  >
                    <h1 className="stats-value" ref={countUpRef}>
                      0
                    </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <p className="stats-title">Projects</p>
            </div>
          </div>

          <hr className="mb-9" />

          <div className="flex justify-between flex-wrap">
            <div className="flex flex-col items-center">
              <div>
                <span className="text-2xl text-white font-font1 font-semibold inline-block">
                  $
                </span>
                <CountUp
                  start={viewPortEntered ? null : 0}
                  end={100}
                  suffix="m+"
                >
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      active={!viewPortEntered}
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setViewPortEntered(true);
                        }
                      }}
                      delayedCall
                    >
                      <h1 className="stats-value inline-block" ref={countUpRef}>
                        0
                      </h1>
                    </VisibilitySensor>
                  )}
                </CountUp>
              </div>
              <p className="stats-title">Valuation</p>
            </div>
            <div className="flex flex-col items-center">
              <CountUp start={viewPortEntered ? null : 0} end={13} suffix="+">
                {({ countUpRef }) => (
                  <VisibilitySensor
                    active={!viewPortEntered}
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setViewPortEntered(true);
                      }
                    }}
                    delayedCall
                  >
                    <h1 className="stats-value " ref={countUpRef}>
                      0
                    </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <p className="stats-title">Countries</p>
            </div>
          </div>
        </div>
      </div>

      {/* CLIENTS SECTION */}
      {/* mobile view start */}
      <div className="pt-16 pb-20 md:hidden">
        <div>
          <div className="flex bg-white ml-3 h-[134px] items-center justify-between pr-4 rounded-tl-lg rounded-bl-lg">
            <div className="">
              <h1 className="text-[25px] text-primary font-medium font-font2 leading-[30px] ml-[30px] w-[158px] relative flex">
                <div className="w-[40px] absolute -left-8  -bottom-3">
                  <lottie-player
                    src="down.json"
                    background="transparent"
                    speed="1"
                    loop
                    autoplay
                  ></lottie-player>{" "}
                </div>
                Brands <br /> that trust us
                <div className="max-w-[70px] absolute -top-3 -right-6 ">
                  <lottie-player
                    src="right.json"
                    background="transparent"
                    speed="1"
                    loop
                    autoplay
                  ></lottie-player>
                </div>
              </h1>
            </div>
            {brands.slice(0, 1).map((brand, idx) => (
              <div className="max-w-[90px]  " key={idx}>
                <img src={brand} alt="" />
              </div>
            ))}
          </div>
          <div className="flex bg-white mt-6 h-[134px] items-center justify-between px-4">
            {brands.slice(1, 4).map((brand, idx) => (
              <div className="max-w-[90px]  " key={idx}>
                <img src={brand} alt="" />
              </div>
            ))}
          </div>
          <div className="flex bg-white mt-6 h-[134px] items-center justify-between px-4">
            {brands.slice(4, 7).map((brand, idx) => (
              <div className="max-w-[90px]  " key={idx}>
                <img src={brand} alt="" />
              </div>
            ))}
          </div>
          <div className="flex bg-white mt-6 mr-3 h-[134px] items-center justify-between px-4 rounded-tr-lg rounded-br-lg">
            {brands.slice(7, 10).map((brand, idx) => (
              <div className="max-w-[90px]  " key={idx}>
                <img src={brand} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* mobile view end */}

      {/* desktop view start */}
      <div className="mobile:hidden pt-20 pb-16">
        <div className="bg-white h-[168px] rounded-tl-lg rounded-bl-lg w-[90%] ml-auto mr-0">
          <div className="flex  items-center justify-between    px-[55px]  h-full">
            <div className="flex">
              <h1 className=" text-[35px] leading-10 text-primary font-font2 w-[213px] mr-10 font-medium flex relative">
                <div className="w-[70px] absolute -left-14 -bottom-6">
                  <lottie-player
                    src="down.json"
                    background="transparent"
                    speed="1"
                    loop
                    autoplay
                  ></lottie-player>{" "}
                </div>
                Brands <br /> that trust us
                <div className="max-w-[120px] absolute -top-5 -right-14 ">
                  <lottie-player
                    src="right.json"
                    background="transparent"
                    speed="1"
                    loop
                    autoplay
                  ></lottie-player>
                </div>
              </h1>
            </div>
            {brands.slice(0, 4).map((brand, idx) => (
              <div className="max-w-[150px]  mr-10" key={idx}>
                <img src={brand} alt="" />
              </div>
            ))}
          </div>
        </div>
        <div className="bg-white mt-6 mr-3 h-[168px] w-[90%] rounded-tr-lg rounded-br-lg">
          <div className="flex container  items-center justify-between  px-[55px] h-full">
            {brands.slice(4, 9).map((brand, idx) => (
              <div className="max-w-[150px]  mr-10" key={idx}>
                <img src={brand} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* desktop view end */}
    </div>
  );
};

export default Stats;
