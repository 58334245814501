export const testimonies = [
  {
    id: 0,
    content:
      " Excellent collaboration award for our steadfast delivery of the very best technical expertise to aid their operations ",
    name: "Huawei",
    position: {
      __html: "Huawei Technologies Awards,<br />2018",
    },
    image: "huaweiTestimonial.png",
    imageLocation:
      "absolute left-[10%] bottom-[7%] md:max-w-[54px] max-w-[30px]",
  },
  // left-1/2 top-1/2 -translate-x-[60%] -translate-y-1/2 md:max-w-[126px] max-w-[85px] absolute
  {
    id: 1,
    content:
      " Swifta has proved to me to be a powerhouse full of highly qualified and motivated professionals who take their responsibilities very seriously. Having worked with several Engineers and Project Managers directly, I have come to appreciate their culture of excellence drive, and this is not only at a professional level but also at their interrelations which makes them great colleagues at the end of the day ",
    name: "Godfrey Mulenga",
    position: {
      __html:
        "Senior Manager, IT Governance<br />and Service Management,<br />MTN Zambia",
    },
    image: "godfrey.png",
    imageLocation:
      "left-1/2 -translate-x-[60%] translate-y-1/3 md:max-w-[84px] max-w-[57px] absolute",
  },
  {
    id: 2,
    content:
      " As we grow, we’re constantly searching for the brightest minds to join the Renmoney team and help us provide simple and convenient financial solutions to our users. Swifta worked with us to provide the right tech talent for our growing team, and we’re happy to keep working with them. ",
    name: "Adija Uzodinma",
    position: {
      __html: "Head, Information Technology<br />& Operations, Renmoney",
    },
    image: "adija.png",
    imageLocation: "right-0 top-[20%] max-w-[84px] max-w-[57px] absolute",
  },
  // {
  //   id: 3,
  //   content:
  //     " consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea. ",
  //   name: "Nneka Nwoke",
  //   position: {
  //     __html: "HR, Airtel",
  //   },
  //   image: "testifier4.png",
  //   imageLocation: "left-[9%] bottom-1/3 md:max-w-[54px] max-w-[36px] absolute",
  // },
  // {
  //   id: 4,
  //   content:
  //     " Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea. ",
  //   name: "Martial Williams",
  //   position: {
  //     __html: "HR, Google",
  //   },
  //   image: "testifier5.png",
  //   imageLocation:
  //     "left-[58%]  -bottom-[5%] md:max-w-[99px] max-w-[67px] absolute",
  // },
];

export const testimonialImgs = [
  "testifier1.png",
  "testifier2.png",
  "testifier3.png",
  "testifier4.png",
  "testifier5.png",
];
