import React from "react";
import Hero from "../../Components/Hero/Hero";
import NavBar from "../../Components/NavBar/NavBar";
import Services from "../../Components/Services/Services";
import TalentPool from "../../Components/TalentPool/TalentPool";
import Stats from "../../Components/Stats/Stats";
import SpecificServices from "../../Components/SpecificServices/SpecificServices";
import Testimonials from "../../Components/Testimonials/Testimonials";
import Footer from "../../Components/Footer/Footer";
const homepage = () => {
  return (
    <>
      <NavBar />
      <Hero />
      <Services />
      <TalentPool />
      <Stats />
      <SpecificServices />
      <Testimonials />
      <Footer />
    </>
  );
};

export default homepage;
