import React from "react";
import { Services } from "./shared";
import Button from "../Button/Button";
import { NavLink } from "react-router-dom";

const SpecificServices = () => {
  return (
    <div className="mt-[51px] mb-[50px] md:mb-14">
      <div className="container ">
        <div className="flex flex-col ">
          {Services.map((service, idx) => (
            <div
              key={idx}
              id={service.title}
              className={`flex flex-col  md:flex-row${
                idx === 1 ? "-reverse" : ""
              } justify-around ${idx !== 2 && "md:mb-[100px] mb-12"}`}
            >
              <div
                className={` flex justify-start md:w-1/2 md:justify-center ${
                  idx === 1 ? "md:ml-7" : "md:mr-7"
                } mobile:mb-5 `}
              >
                <img
                  src={service.image}
                  alt=""
                  className="max-w-[364px] w-full"
                />
              </div>
              <div className="flex flex-col  md:w-1/2">
                <h1 className="text-2xl md:text-4xl text-primary font-font2 font-medium mb-3">
                  {service.title}
                </h1>
                <p
                  className="text-primary font-font1 font-normal text-justify mb-2"
                  dangerouslySetInnerHTML={service.content1}
                />
                <p
                  className="text-primary font-font1 font-normal mb-3 text-justify"
                  dangerouslySetInnerHTML={service.content2}
                />
                <div className="md:grid md:grid-cols-mobilePlans  mb-3">
                  {service.features.map((feature, idx) => (
                    <ul key={idx} className="">
                      <li className="flex mb-[10px]   items-center">
                        <div className="mr-2 ">
                          <img src="tick.svg" alt="tick" className="" />
                        </div>
                        <p className="text-primary font-font1 font-medium ">
                          {feature}
                        </p>
                      </li>
                    </ul>
                  ))}
                </div>
                <div>
                  {service.title !== "Placement" ? (
                    <NavLink to="/hire-talent">
                      <Button name={service.button} />
                    </NavLink>
                  ) : (
                    <a
                      href="https://forms.gle/zbvE8T4tqQHiJa6x6"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button name="I'm in" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SpecificServices;
