export const talents = [
  {
    image: "business-solution.svg",
    title: "Solution architects",
    alt: "hire solution architect",
  },

  {
    image: "audio-software.svg",
    title: "Product managers",
    alt: "product manager for hire",
  },
  {
    image: "business-website.svg",
    title: "Product specialists",
    alt: "hire product specialist",
  },
  {
    image: "administrator.svg",
    title: "Project managers",
    alt: "project manager for hire",
  },
  {
    image: "business-presentation.svg",
    title: "Business analyst",
    alt: "business analyst for hire",
  },
  {
    image: "online-hotel-booking.svg",
    title: "UI/UX designers",
    alt: "hire ui ux designer",
  },
  {
    image: "web-development.svg",
    title: "Frontend developers",
    alt: "hire front end developer",
  },
  {
    image: "computer-designing.svg",
    title: "Backend developers",
    alt: "back end developer for hire",
  },
  {
    image: "cloud-storage.svg",
    title: "DevOps engineer",
    alt: "hire devops engineer",
  },
  {
    image: "feedback.svg",
    title: "Software testers",
    alt: "software tester for hire",
  },
  {
    image: "user-authentication.svg",
    title: "Support engineers",
    alt: "hire support engineer",
  },
  {
    image: "administrator.svg",
    title: "Program leads",
    alt: "hire Program leads",
  },
  {
    image: "strategic-planing.svg",
    title: "Technical lead",
    alt: "hire Technical lead",
  },
  {
    image: "cloud-technology.svg",
    title: "HRIS impl. consultant",
    alt: "hire HRIS impl. consultant",
  },
  {
    image: "cloud-data-center.svg",
    title: "System integration",
    alt: "hire system integration engineer",
  },
  {
    image: "business-performance.svg",
    title: "ERP sales manager",
    alt: "hire ERP sales manager",
  },
];
