import React, { useState } from "react";
import { useFormik, Field, FormikProvider } from "formik";
import PopUp from "../PopUp/PopUp";
import { NavLink } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
const axios = require("axios").default;

const steps = ["Hiring options", "Fill form"];

function QontoStepIcon(props) {
  const { active, completed } = props;

  return (
    <div>
      {active || completed ? (
        <div className="w-6 h-6 border-arrow border border-solid rounded-full flex justify-center items-center step1 mb-0">
          <div className="w-3 h-3 bg-arrow rounded-full"></div>
        </div>
      ) : (
        <div className="w-6 h-6 border-[#707070] border border-solid rounded-full flex justify-center items-center step1">
          <div className="w-3 h-3 bg-[#707070] rounded-full"></div>
        </div>
      )}
    </div>
  );
}
const Form = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [hireType, setHireType] = useState("");

  const [activeStep, setActiveStep] = useState(0);

  const handleRecruitmentToggle = () => {
    setHireType("Recruitment");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleOutsourcingToggle = () => {
    setHireType("Outsourcing");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setHireType("");
    setActiveStep(0);
  };
  const formik = useFormik({
    initialValues: {
      companyName: "",
      industry: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      location: "",
      employment: "",
      workType: "",
      info: "",
      hireType: hireType,
      role: "",
      projectType: "",
      duration: "",
    },
    enableReinitialize: true,
    validate: (values) => {
      let errors = {};

      if (!values.companyName) {
        errors.companyName = "Required";
      }
      if (!values.industry) {
        errors.industry = "Required";
      }
      if (!values.firstName) {
        errors.firstName = "Required";
      }
      if (!values.lastName) {
        errors.lastName = "Required";
      }
      if (!values.email) {
        errors.email = "Required";
      } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.email)) {
        errors.email = "Invalid email Format";
      } else {
        if (!/^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo|apple|outlook)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/.test(values.email)){
          errors.email = "Only work emails are allowed";
        }
      }
      

      if (!values.phoneNumber) {
        errors.phoneNumber = "Required";
      } else if (!/^\d{11}$/.test(values.phoneNumber)) {
        errors.phoneNumber = "Invalid Phone Number";
      }

      if (!values.location) {
        errors.location = "Required";
      }
      if (!values.workType) {
        errors.workType = "Required";
      }
      if (!values.role && hireType === "Recruitment") {
        errors.role = "Required";
      }
      if (!values.employment && hireType === "Recruitment") {
        errors.employment = "Required";
      }
      if (!values.info) {
        errors.info = "Required";
      }
      if (!values.projectType && hireType === "Outsourcing") {
        errors.projectType = "Required";
      }
      if (!values.duration && hireType === "Outsourcing") {
        errors.duration = "Required";
      }
      return errors;
    },

    onSubmit: (values) => {
      // console.log(values);
      const {
        companyName,
        industry,
        firstName,
        lastName,
        email,
        phoneNumber,
        role,
        location,
        employment,
        workType,
        info,
        hireType,
        projectType,
        duration,
      } = values;

      const config = {
        url: `https://script.google.com/a/macros/infibranches.com/s/AKfycbyktjqBLb-FSToyU3UfO9l3bCWFCjeV6pak7WeevdSPbXWR9OiSg8Yd3Cn0pL5_ANq-/exec?service_type=tech_for_hire_client&org_name=${companyName}&first_name=${firstName}&last_name=${lastName}&industry=${industry}&work_email=${email}&phone_no=${phoneNumber}&employment_role=${role}&work_location=${location}&employment_type=${employment}&work_type=${workType}&how_did_you_hear=${info}&service=${hireType}&project_type=${projectType}&project_duration=${duration}`,
        headers: {
          "Content-Type": "text/plain",
        },
      };

      const mailAPI = {
        from: "Swifta Tech Talent <contact@swiftatechtalent.com>",
        to: [
          "goladipupo@swifta.com",
          // "akareem@swifta.com",
          // "toyadele@swifta.com",
          // "korelaja@swifta.com",
          // "fodeyemi@swifta.com",
          // "ffatuase@swifta.com",
          // "ofamade@swifta.com",
          // "sokoeka@swifta.com",
        ],
        domain: "tech4hire",
        subject: "New Lead on SwiftaTechTalent CRM",
        text: "Texting",
        content:
          '<div className="h-14 rounded-md p-4 bg-slate-400 text-center"><p>You have a new form entry on the TechForHire Lead Generation CRM.<br /> Click <a href="https://docs.google.com/spreadsheets/d/1dZw9wDVQ_zY1YJwLXwD1V86q2r6tKmYLRPnRYGDEP_0/edit#gid=73758469">link</a> to view. You will need to refresh the sheet to see the updates via these steps:<br /><br /> SwiftaMenu ➡️ Refresh Sheet ➡️ Ok </p> </div>',
      };

      const clientMailAPI = {
        from: "Swifta Tech Talent <contact@swiftatechtalent.com>",
        to: [`${email}`],
        domain: "tech4hire",
        subject: "Your tech talent request is processing...",
        text: "Texting",
        content: `<div className="h-14 rounded-md p-4 bg-slate-400 text-center"><p>Dear ${firstName}, <br /><br /> We are thrilled to receive your request which is being processed for the right talent fit. Our sales team will contact you shortly to speed things up. <br /> <br />For more information or assistance, please contact us at  info.techtalent@swifta.com.</p> </div>`,
      };
      axios(config)
        .then((res) => {
          if (res.request.status === 200) {
            setOpenSuccess(true);
            formik.resetForm();
          }
          axios
            .post("https://api.omnibranches.com/v1/send-mail", mailAPI)
            .then((res) => {
              // console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
          axios
            .post("https://api.omnibranches.com/v1/send-mail", clientMailAPI)
            .then((res) => {
              // console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          alert("Please try again");
        });

      //Mail API Notification start

      //Mail API Notification End
    },
  });
  return (
    <div className="md:w-1/2 md:overflow-y-scroll md:h-screen">
      <div className="mx-4 my-6 md:mx-14  md:my-9">
        <div className="mb-3 md:mb-5">
          {hireType !== "" ? (
            <div className="mb-3 cursor-pointer" onClick={handleBack}>
              <img src="left.svg" alt="Left Arrow" />
            </div>
          ) : (
            <NavLink to="/">
              <div className="mb-3" onClick={() => setHireType("")}>
                <img src="left.svg" alt="Left Arrow" />
              </div>
            </NavLink>
          )}
          <h1 className="text-2xl md:text-4xl font-font2 font-medium text-primary">
            Tech hire made easy
          </h1>
          <p className="text-primary font-font1 mb-4 md:mb-5">
            It really isn't rocket science. You just need to know where to
            start... and that's{" "}
            <span className="font-semibold">RIGHT HERE!</span>
          </p>

          <div className=" mb-10 w-full">
            <Stepper alternativeLabel activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={index}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>

          <p className="text-lg font-font1 font-medium text-primary mb-5">
            Tell us what you need and we'll hook you up.
          </p>
        </div>
        <FormikProvider value={formik}>
          <form
            action=""
            onSubmit={formik.handleSubmit}
            className="mb-52 md:mb-28"
          >
            {hireType === "" && (
              <div>
                <div
                  className={`border border-solid border-[#9DBBED40] rounded-xl cursor-pointer mb-5 flex items-center p-2 md:p-4 hover:border-[#0E244B80] ${
                    hireType === "Recruitment"
                      ? "text-secondary bg-[#F5F8FB]"
                      : "text-[#707070]"
                  }`}
                  onClick={handleRecruitmentToggle}
                  value={formik.values.hireType}
                >
                  <div className="mr-3">
                    <img src="hiring-form.svg" alt="" />
                  </div>
                  <div className="text-primary">
                    <h1 className="text-lg font-font1 font-semibold">
                      Recruitment
                    </h1>
                    <p className="text-xs md:text-sm">
                      We match your tech vacancies to the RIGHT candidates.
                    </p>
                  </div>
                </div>
                <div
                  className={`border border-solid border-[#9DBBED40] rounded-xl cursor-pointer flex items-center p-2 md:p-4 hover:border-[#0E244B80] 
              ${
                hireType === "Outsourcing"
                  ? "text-secondary bg-[#F5F8FB]"
                  : "text-[#707070]"
              }
              `}
                  onClick={handleOutsourcingToggle}
                  value={formik.values.hireType}
                >
                  <div className="mr-3">
                    <img src="outsourcing-form.svg" alt="" />
                  </div>
                  <div className="text-primary">
                    <h1 className="text-lg font-font1 font-semibold">
                      Outsourcing
                    </h1>
                    <p className="text-xs md:text-sm">
                      FOCUS on deliverables while we set up your project team
                    </p>
                  </div>
                </div>
              </div>
            )}

            {hireType === "Recruitment" && (
              <div>
                <div className="flex flex-wrap ">
                  <div className="flex flex-col w-full md:w-60 md:mr-8 mb-5">
                    <label
                      htmlFor="companyName"
                      className="mb-2 flex items-center justify-between text-primary font-font1"
                    >
                      Company name
                      {formik.touched.companyName &&
                      formik.errors.companyName ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.companyName}
                        </p>
                      ) : null}
                    </label>
                    <input
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary"
                      type="text"
                      id="companyName"
                      name="companyName"
                      onChange={formik.handleChange}
                      value={formik.values.companyName}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="flex flex-col w-full md:w-60 mb-5">
                    <label
                      htmlFor="industry"
                      className="mb-2 flex items-center justify-between text-primary font-font1"
                    >
                      Industry
                      {formik.touched.industry && formik.errors.industry ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.industry}
                        </p>
                      ) : null}
                    </label>
                    <select
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary text-primary font-font1 font-semibold"
                      name="industry"
                      onChange={formik.handleChange}
                      value={formik.values.industry}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" label="Select" />
                      <option value="Accounting">Accounting</option>
                      <option value="Advertisement">Advertisement</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Banking">Banking</option>
                      <option value="Capital Market">Capital Market</option>
                      <option value="Capital Market">Capital Market</option>
                      <option value="Education">Education</option>
                      <option value="Engineering">Engineering</option>
                      <option value="FMCG">FMCG</option>
                      <option value="Fintech">Fintech</option>
                      <option value="Human Resource">Human Resource</option>
                      <option value="Information Technology">
                        Information Technology
                      </option>
                      <option value="Insurance">Insurance</option>
                      <option value="Legal">Legal</option>
                      <option value="Manufacturing">Manufacturing</option>
                      <option value="Media">Media</option>
                      <option value="Pension">Pension</option>
                      <option value="Pharmaceutical">Pharmaceutical</option>
                      <option value="Retail">Retail</option>
                      <option value="Supply Chain">Supply Chain</option>
                      <option value="Telecommunication">
                        Telecommunication
                      </option>
                      <option value="Textile">Textile</option>
                      <option value="Transportation">Transportation</option>
                      <option value="Wholesale">Wholesale</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>

                <div className="flex flex-wrap ">
                  <div className="flex flex-col w-full md:w-60 md:mr-8 mb-5">
                    <label
                      htmlFor="firstName"
                      className="mb-2 flex items-center justify-between text-primary font-font1"
                    >
                      First name
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.firstName}
                        </p>
                      ) : null}
                    </label>
                    <input
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary"
                      type="text"
                      id="firstName"
                      name="firstName"
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="flex flex-col w-full md:w-60 mb-5">
                    <label
                      htmlFor="lastName"
                      className="mb-2 flex items-center justify-between text-primary font-font1"
                    >
                      Last name
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.lastName}
                        </p>
                      ) : null}
                    </label>
                    <input
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary"
                      type="text"
                      id="lastName"
                      name="lastName"
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>

                <div className="flex flex-wrap ">
                  <div className="flex flex-col w-full md:w-60 md:mr-8 mb-5">
                    <label
                      htmlFor="email"
                      className="mb-2 flex items-center justify-between text-primary font-font1"
                    >
                      Work email
                      {formik.touched.email && formik.errors.email ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.email}
                        </p>
                      ) : null}
                    </label>
                    <input
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary"
                      type="text"
                      id="email"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="flex flex-col w-full md:w-60  mb-5">
                    <label
                      htmlFor="phoneNumber"
                      className="mb-2 flex items-center justify-between text-primary font-font1"
                    >
                      Phone number
                      {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.phoneNumber}
                        </p>
                      ) : null}
                    </label>
                    <input
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary"
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      onChange={formik.handleChange}
                      value={formik.values.phoneNumber}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>

                <div className="flex flex-wrap ">
                  <div className="flex flex-col w-full md:w-60 mb-5">
                    <label
                      htmlFor="location"
                      className="mb-2 flex items-center font-font1 justify-between"
                    >
                      Work location
                      {formik.touched.location && formik.errors.location ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.location}
                        </p>
                      ) : null}
                    </label>
                    <select
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary text-primary font-font1 font-semibold"
                      name="location"
                      onChange={formik.handleChange}
                      value={formik.values.location}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" label="Select state" />
                      <option value="Abia">Abia</option>
                      <option value="Adamawa">Adamawa</option>
                      <option value="Akwa Ibom">Akwa Ibom</option>
                      <option value="Anambra">Anambra</option>
                      <option value="Bauchi">Bauchi</option>
                      <option value="Bayelsa">Bayelsa</option>
                      <option value="Benue">Benue</option>
                      <option value="Borno">Borno</option>
                      <option value="Cross River">Cross River</option>
                      <option value="Delta">Delta</option>
                      <option value="Ebonyi">Ebonyi</option>
                      <option value="Edo">Edo</option>
                      <option value="Ekiti">Ekiti</option>
                      <option value="Enugu">Enugu</option>
                      <option value="FCT">Federal Capital Territory</option>
                      <option value="Gombe">Gombe</option>
                      <option value="Imo">Imo</option>
                      <option value="Jigawa">Jigawa</option>
                      <option value="Kaduna">Kaduna</option>
                      <option value="Kano">Kano</option>
                      <option value="Katsina">Katsina</option>
                      <option value="Kebbi">Kebbi</option>
                      <option value="Kogi">Kogi</option>
                      <option value="Kwara">Kwara</option>
                      <option value="Lagos">Lagos</option>
                      <option value="Nasarawa">Nasarawa</option>
                      <option value="Niger">Niger</option>
                      <option value="Ogun">Ogun</option>
                      <option value="Ondo">Ondo</option>
                      <option value="Osun">Osun</option>
                      <option value="Oyo">Oyo</option>
                      <option value="Plateau">Plateau</option>
                      <option value="Rivers">Rivers</option>
                      <option value="Sokoto">Sokoto</option>
                      <option value="Taraba">Taraba</option>
                      <option value="Yobe">Yobe</option>
                      <option value="Zamfara">Zamfara</option>
                    </select>
                  </div>
                </div>

                {/* <div className="relative w-[200px] h-[50px] ">
              <input
                type="checkbox"
                id="switcher"
                className="group appearance-none w-[200px] h-[50px] z-20 flex items-center justify-between  bg-slate-700 before:content-['Recruitment'] before:left-[20px] after:content-['Outsourcing'] after:right-[20px] checked:bg-amber-200 duration-150 checked:before:text-white checked:after:text-white checked:before:transition-colors checked:after:transition-colors checked:before:duration-150 "
              />
              <label
                htmlFor="switcher"
                className="z-10 absolute top-2 bottom-1 group-checked:left-2 group-checked:right-[100px] group-checked:bg-black group-checked:transition"
              ></label>
               <span className=""></span> 
            </div> 
            */}
                {/* <div className={`${hireType !== "Recruitment" && "hidden"}`}> */}
                <div className="flex flex-wrap ">
                  <div className="flex flex-col w-full md:w-60 md:mr-8 mb-5">
                    <label
                      htmlFor="role"
                      className="mb-2 flex items-center justify-between text-primary font-font1"
                    >
                      What role are you hiring for?
                      {formik.touched.role && formik.errors.role ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.role}
                        </p>
                      ) : null}
                    </label>
                    <select
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary text-primary font-font1 font-semibold"
                      name="role"
                      onChange={formik.handleChange}
                      value={hireType === "Recruitment" && formik.values.role}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" label="Select a role" />
                      <option value="Backend Developers">
                        Backend Developers
                      </option>
                      <option value="Business Analysts">
                        Business Analysts
                      </option>
                      <option value="DevOps Engineers">DevOps Engineers</option>
                      <option value="ERP Sales Manager">
                        ERP Sales Manager
                      </option>
                      <option value="Frontend Developers">
                        Frontend Developers
                      </option>
                      <option value="HRIS Implementation Consultant">
                        HRIS Implementation Consultant
                      </option>
                      <option value="Product Specialists">
                        Product Specialists
                      </option>
                      <option value="Product managers">Product managers</option>
                      <option value="Program Leads">Program Leads</option>
                      <option value="Project Managers">Project Managers</option>
                      <option value="Senior Project Manager">
                        Senior Project Manager
                      </option>
                      <option value="Software Testers">Software Testers</option>
                      <option value="Solution architects">
                        Solution architects
                      </option>
                      <option value="Support engineers">
                        Support engineers
                      </option>
                      <option value="System Integration">
                        System Integration
                      </option>
                      <option value="Technical Lead">Technical Lead</option>
                      <option value="UI/UX Designers">UI/UX Designers</option>
                    </select>
                  </div>
                </div>

                <div className="max-w-[240px] mb-5">
                  <div
                    id="employment-group"
                    className="flex items-center justify-between mb-2 text-primary font-font1"
                  >
                    Employment type
                    {formik.touched.employment && formik.errors.employment ? (
                      <p className="text-xs text-red-600">
                        {formik.errors.employment}
                      </p>
                    ) : null}
                  </div>
                  <div
                    role="group"
                    aria-labelledby="employment-group"
                    className="flex flex-wrap justify-between"
                  >
                    <label className="flex items-center">
                      <Field type="radio" name="employment" value="Full-time" />
                      <p className="ml-2 text-primary font-font1 font-medium">
                        {" "}
                        Full-time{" "}
                      </p>
                    </label>
                    <label className="flex items-center">
                      <Field type="radio" name="employment" value="Contract" />
                      <p className="ml-2 text-primary font-font1 font-medium">
                        {" "}
                        Contract{" "}
                      </p>
                    </label>
                  </div>
                </div>
                <div className="max-w-[340px] mb-5">
                  <div
                    id="work-type"
                    className="flex items-center justify-between mb-2 text-primary font-font1"
                  >
                    Work type
                    {formik.touched.workType && formik.errors.workType ? (
                      <p className="text-xs text-red-600">
                        {formik.errors.workType}
                      </p>
                    ) : null}
                  </div>
                  <div
                    role="group"
                    aria-labelledby="work-type"
                    className="flex flex-wrap justify-between"
                  >
                    <label className="flex items-center">
                      <Field type="radio" name="workType" value="Onsite" />
                      <p className="ml-2 text-primary font-font1 font-medium">
                        {" "}
                        Onsite{" "}
                      </p>
                    </label>
                    <label className="flex items-center">
                      <Field type="radio" name="workType" value="Hybrid" />
                      <p className="ml-2 text-primary font-font1 font-medium">
                        {" "}
                        Hybrid{" "}
                      </p>
                    </label>
                    <label className="flex items-center">
                      <Field type="radio" name="workType" value="Remote" />
                      <p className="ml-2 text-primary font-font1 font-medium">
                        {" "}
                        Remote{" "}
                      </p>
                    </label>
                  </div>
                </div>

                <div className="flex flex-col w-full md:w-60 mb-5">
                  <label
                    htmlFor="info"
                    className="mb-2 flex items-center justify-between text-primary font-font1"
                  >
                    How did you hear about us?
                    {formik.touched.info && formik.errors.info ? (
                      <p className="text-xs text-red-600">
                        {formik.errors.info}
                      </p>
                    ) : null}
                  </label>
                  <select
                    className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary text-primary font-font1 font-semibold"
                    name="info"
                    onChange={formik.handleChange}
                    value={formik.values.info}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" label="Select" />
                    <option value="Facebook">Facebook</option>
                    <option value="Friends/Colleagues">
                      Friends/Colleagues
                    </option>
                    <option value="Instagram">Instagram</option>
                    <option value="Linkedin">Linkedin</option>
                    <option value="Twitter">Twitter</option>
                    <option value="Others">Others</option>
                  </select>
                </div>

                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className={`w-[190px] h-[50px] bg-secondary rounded border border-solid border-border border-opacity-50 text-white font-font1 font-semibold text-lg ${
                    formik.isSubmitting &&
                    "disabled:cursor-not-allowed disabled:bg-gray-400 disabled:border-none"
                  }`}
                >
                  Let's do this
                </button>
                <p className="mb-7 text-primary font-font1 font-medium text-sm mt-5">
                  By clicking above, you agree to our{" "}
                  <NavLink
                    to="/terms-of-use"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-secondary underline"
                  >
                    Terms of use
                  </NavLink>{" "}
                  and consent to our{" "}
                  <NavLink
                    to="/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-secondary underline"
                  >
                    Privacy policy
                  </NavLink>
                  .
                </p>
              </div>
            )}

            {hireType === "Outsourcing" && (
              <div>
                <div className="flex flex-wrap ">
                  <div className="flex flex-col w-full md:w-60 md:mr-8 mb-5">
                    <label
                      htmlFor="companyName"
                      className="mb-2 flex items-center justify-between text-primary font-font1"
                    >
                      Company name
                      {formik.touched.companyName &&
                      formik.errors.companyName ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.companyName}
                        </p>
                      ) : null}
                    </label>
                    <input
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary"
                      type="text"
                      id="companyName"
                      name="companyName"
                      onChange={formik.handleChange}
                      value={formik.values.companyName}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="flex flex-col w-full md:w-60 mb-5">
                    <label
                      htmlFor="industry"
                      className="mb-2 flex items-center justify-between text-primary font-font1"
                    >
                      Industry
                      {formik.touched.industry && formik.errors.industry ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.industry}
                        </p>
                      ) : null}
                    </label>
                    <select
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary text-primary font-font1 font-semibold"
                      name="industry"
                      onChange={formik.handleChange}
                      value={formik.values.industry}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" label="Select" />
                      <option value="Accounting">Accounting</option>
                      <option value="Advertisement">Advertisement</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Banking">Banking</option>
                      <option value="Capital Market">Capital Market</option>
                      <option value="Capital Market">Capital Market</option>
                      <option value="Education">Education</option>
                      <option value="Engineering">Engineering</option>
                      <option value="FMCG">FMCG</option>
                      <option value="Fintech">Fintech</option>
                      <option value="Human Resource">Human Resource</option>
                      <option value="Information Technology">
                        Information Technology
                      </option>
                      <option value="Insurance">Insurance</option>
                      <option value="Legal">Legal</option>
                      <option value="Manufacturing">Manufacturing</option>
                      <option value="Media">Media</option>
                      <option value="Pension">Pension</option>
                      <option value="Pharmaceutical">Pharmaceutical</option>
                      <option value="Retail">Retail</option>
                      <option value="Supply Chain">Supply Chain</option>
                      <option value="Telecommunication">
                        Telecommunication
                      </option>
                      <option value="Textile">Textile</option>
                      <option value="Transportation">Transportation</option>
                      <option value="Wholesale">Wholesale</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>

                <div className="flex flex-wrap ">
                  <div className="flex flex-col w-full md:w-60 md:mr-8 mb-5">
                    <label
                      htmlFor="firstName"
                      className="mb-2 flex items-center justify-between text-primary font-font1"
                    >
                      First name
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.firstName}
                        </p>
                      ) : null}
                    </label>
                    <input
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary"
                      type="text"
                      id="firstName"
                      name="firstName"
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="flex flex-col w-full md:w-60 mb-5">
                    <label
                      htmlFor="lastName"
                      className="mb-2 flex items-center justify-between text-primary font-font1"
                    >
                      Last name
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.lastName}
                        </p>
                      ) : null}
                    </label>
                    <input
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary"
                      type="text"
                      id="lastName"
                      name="lastName"
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>

                <div className="flex flex-wrap ">
                  <div className="flex flex-col w-full md:w-60 md:mr-8 mb-5">
                    <label
                      htmlFor="email"
                      className="mb-2 flex items-center justify-between text-primary font-font1"
                    >
                      Work email
                      {formik.touched.email && formik.errors.email ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.email}
                        </p>
                      ) : null}
                    </label>
                    <input
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary"
                      type="text"
                      id="email"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="flex flex-col w-full md:w-60  mb-5">
                    <label
                      htmlFor="phoneNumber"
                      className="mb-2 flex items-center justify-between text-primary font-font1"
                    >
                      Phone number
                      {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.phoneNumber}
                        </p>
                      ) : null}
                    </label>
                    <input
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary"
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      onChange={formik.handleChange}
                      value={formik.values.phoneNumber}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>

                <div className="flex flex-wrap ">
                  <div className="flex flex-col w-full md:w-60 mb-5">
                    <label
                      htmlFor="location"
                      className="mb-2 flex items-center font-font1 justify-between"
                    >
                      Work location
                      {formik.touched.location && formik.errors.location ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.location}
                        </p>
                      ) : null}
                    </label>
                    <select
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary text-primary font-font1 font-semibold"
                      name="location"
                      onChange={formik.handleChange}
                      value={formik.values.location}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" label="Select state" />
                      <option value="Abia">Abia</option>
                      <option value="Adamawa">Adamawa</option>
                      <option value="Akwa Ibom">Akwa Ibom</option>
                      <option value="Anambra">Anambra</option>
                      <option value="Bauchi">Bauchi</option>
                      <option value="Bayelsa">Bayelsa</option>
                      <option value="Benue">Benue</option>
                      <option value="Borno">Borno</option>
                      <option value="Cross River">Cross River</option>
                      <option value="Delta">Delta</option>
                      <option value="Ebonyi">Ebonyi</option>
                      <option value="Edo">Edo</option>
                      <option value="Ekiti">Ekiti</option>
                      <option value="Enugu">Enugu</option>
                      <option value="FCT">Federal Capital Territory</option>
                      <option value="Gombe">Gombe</option>
                      <option value="Imo">Imo</option>
                      <option value="Jigawa">Jigawa</option>
                      <option value="Kaduna">Kaduna</option>
                      <option value="Kano">Kano</option>
                      <option value="Katsina">Katsina</option>
                      <option value="Kebbi">Kebbi</option>
                      <option value="Kogi">Kogi</option>
                      <option value="Kwara">Kwara</option>
                      <option value="Lagos">Lagos</option>
                      <option value="Nasarawa">Nasarawa</option>
                      <option value="Niger">Niger</option>
                      <option value="Ogun">Ogun</option>
                      <option value="Ondo">Ondo</option>
                      <option value="Osun">Osun</option>
                      <option value="Oyo">Oyo</option>
                      <option value="Plateau">Plateau</option>
                      <option value="Rivers">Rivers</option>
                      <option value="Sokoto">Sokoto</option>
                      <option value="Taraba">Taraba</option>
                      <option value="Yobe">Yobe</option>
                      <option value="Zamfara">Zamfara</option>
                    </select>
                  </div>
                </div>

                {/* </div> */}

                {/* <div className={`${hireType !== "Outsourcing" && "hidden"}`}> */}
                <div className="flex flex-wrap">
                  <div className="flex flex-col w-full md:w-60 md:mr-8 mb-5">
                    <label
                      htmlFor="projectType"
                      className="mb-2 flex items-center justify-between text-primary font-font1"
                    >
                      What type of project is it?
                      {formik.touched.projectType &&
                      formik.errors.projectType ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.projectType}
                        </p>
                      ) : null}
                    </label>
                    <select
                      className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary text-primary font-font1 font-semibold"
                      name="projectType"
                      onChange={formik.handleChange}
                      value={formik.values.projectType}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" label="Select a project type" />
                      <option value="Application Support">
                        Application Support
                      </option>
                      <option value="Artificial Intelligence">
                        Artificial Intelligence
                      </option>
                      <option value="Augmented Reality">
                        Augmented Reality
                      </option>
                      <option value="Blockchain">Blockchain</option>
                      <option value="Business Analysis">
                        Business Analysis
                      </option>
                      <option value="Business Analytics">
                        Business Analytics
                      </option>
                      <option value="Business Process Optimization">
                        Business Process Optimization
                      </option>
                      <option value="Call Center Setup and Management">
                        Call Center Setup and Management
                      </option>
                      <option value="Cloud Computing">Cloud Computing</option>
                      <option value="Cryptocurrency">Cryptocurrency</option>
                      <option value="Cybersecurity">Cybersecurity</option>
                      <option value="Data Analytics">Data Analytics</option>
                      <option value="Digital Service Training">
                        Digital Service Training
                      </option>
                      <option value="Digital Transformation">
                        Digital Transformation
                      </option>
                      <option value="IT Service Management">
                        IT Service Management
                      </option>
                      <option value="Machine Learning">Machine Learning</option>
                      <option value="Mobile Application Development/Upgrade">
                        Mobile Application Development/Upgrade
                      </option>
                      <option value="Network Infrastructure Setup/Upgrade">
                        Network Infrastructure Setup/Upgrade
                      </option>
                      <option value="Process Automation">
                        Process Automation
                      </option>
                      <option value="Process Engineering">
                        Process Engineering
                      </option>
                      <option value="Product Design">Product Design</option>
                      <option value="Product Marketing">
                        Product Marketing
                      </option>
                      <option value="Project Management">
                        Project Management
                      </option>
                      <option value="Research and Development">
                        Research and Development
                      </option>
                      <option value="Software Development/Upgrade">
                        Software Development/Upgrade
                      </option>
                      <option value="User Experience Research">
                        User Experience Research
                      </option>
                      <option value="Web Application Development/Upgrade">
                        Web Application Development/Upgrade
                      </option>
                    </select>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-[32rem] mb-5">
                    <div
                      id="duration-group"
                      className="flex items-center justify-between mb-2 text-primary font-font1"
                    >
                      Project duration
                      {formik.touched.duration && formik.errors.duration ? (
                        <p className="text-xs text-red-600">
                          {formik.errors.duration}
                        </p>
                      ) : null}
                    </div>
                    <div
                      role="group"
                      aria-labelledby="duration-group"
                      className="flex  flex-wrap justify-between"
                    >
                      <label className="flex items-center">
                        <Field
                          type="radio"
                          name="duration"
                          value="< 3 months"
                        />
                        <p className="ml-2 text-primary font-font1 font-medium">
                          &lt; 3 months
                        </p>
                      </label>
                      <label className="flex items-center">
                        <Field
                          type="radio"
                          name="duration"
                          value="3 - 6 months"
                        />
                        <p className="ml-2 text-primary font-font1 font-medium">
                          3 - 6 months
                        </p>
                      </label>
                      <label className="flex items-center">
                        <Field
                          type="radio"
                          name="duration"
                          value="6 - 12 months"
                        />
                        <p className="ml-2 text-primary font-font1 font-medium">
                          6 - 12 months
                        </p>
                      </label>
                      <label className="flex items-center">
                        <Field type="radio" name="duration" value="1 year +" />
                        <p className="ml-2 text-primary font-font1 font-medium">
                          1 year +
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                <div className="max-w-[340px] mb-5">
                  <div
                    id="work-type"
                    className="flex items-center justify-between mb-2 text-primary font-font1"
                  >
                    Work type
                    {formik.touched.workType && formik.errors.workType ? (
                      <p className="text-xs text-red-600">
                        {formik.errors.workType}
                      </p>
                    ) : null}
                  </div>
                  <div
                    role="group"
                    aria-labelledby="work-type"
                    className="flex flex-wrap justify-between"
                  >
                    <label className="flex items-center">
                      <Field type="radio" name="workType" value="Onsite" />
                      <p className="ml-2 text-primary font-font1 font-medium">
                        {" "}
                        Onsite{" "}
                      </p>
                    </label>
                    <label className="flex items-center">
                      <Field type="radio" name="workType" value="Hybrid" />
                      <p className="ml-2 text-primary font-font1 font-medium">
                        {" "}
                        Hybrid{" "}
                      </p>
                    </label>
                    <label className="flex items-center">
                      <Field type="radio" name="workType" value="Remote" />
                      <p className="ml-2 text-primary font-font1 font-medium">
                        {" "}
                        Remote{" "}
                      </p>
                    </label>
                  </div>
                </div>

                <div className="flex flex-col w-full md:w-60 mb-5">
                  <label
                    htmlFor="info"
                    className="mb-2 flex items-center justify-between text-primary font-font1"
                  >
                    How did you hear about us?
                    {formik.touched.info && formik.errors.info ? (
                      <p className="text-xs text-red-600">
                        {formik.errors.info}
                      </p>
                    ) : null}
                  </label>
                  <select
                    className="w-full h-10 px-3 bg-primary bg-opacity-10 rounded-md focus:border focus:outline-none focus:border-secondary text-primary font-font1 font-semibold"
                    name="info"
                    onChange={formik.handleChange}
                    value={formik.values.info}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" label="Select" />
                    <option value="Facebook">Facebook</option>
                    <option value="Friends/Colleagues">
                      Friends/Colleagues
                    </option>
                    <option value="Instagram">Instagram</option>
                    <option value="Linkedin">Linkedin</option>
                    <option value="Twitter">Twitter</option>
                    <option value="Others">Others</option>
                  </select>
                </div>

                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className={`w-[190px] h-[50px] bg-secondary rounded border border-solid border-border border-opacity-50 text-white font-font1 font-semibold text-lg ${
                    formik.isSubmitting &&
                    "disabled:cursor-not-allowed disabled:bg-gray-400 disabled:border-none"
                  }`}
                >
                  Let's do this
                </button>
                <p className="mb-7 text-primary font-font1 font-medium text-sm mt-5">
                  By clicking above, you agree to our{" "}
                  <NavLink
                    to="/terms-of-use"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-secondary underline"
                  >
                    Terms of use
                  </NavLink>{" "}
                  and consent to our{" "}
                  <NavLink
                    to="/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-secondary underline"
                  >
                    Privacy policy
                  </NavLink>
                  .
                </p>
              </div>
            )}
          </form>
        </FormikProvider>
      </div>
      <PopUp open={openSuccess} handleclose={() => setOpenSuccess(false)} />
    </div>
  );
};

export default Form;
