import React from "react";
import { NavLink } from "react-router-dom";

const TermsOfUse = () => {
  return (
    <div className="container">
      <div className="mt-4 px-3">
        <nav>
          <div className="max-w-[100px] md:max-w-xs">
            <NavLink to="/">
              <img src="logo.png" alt="" />
            </NavLink>
          </div>
        </nav>
        <div className="mt-6 mb-60">
          <h1 className=" text-center text-primary font-font2 font-bold text-xl md:text-3xl mb-2">
            Swifta Tech4Hire Terms of Use
          </h1>
          <p className="mb-10">
            Effective date: April 1, 2022 <br />
            Applicable to: www.swiftatechtalent.com
          </p>
          <h1 className="text-primary font-font2 font-bold">1. DEFINITIONS</h1>
          <p className="text-primary font-font2 text-justify">
            <span className="font-bold">"Content"</span> means any materials,
            information or documentation that we may provide to you in
            connection with your use of the products including documentation,
            data, information developed any use and other materials which may
            assist in your use of the goods or service. <br />
            <span className="font-bold">"Materials"</span> means the Website,
            which is known as: www.swiftacloud.com including all pages,
            subpages, all blogs, forums and other connected Internet content
            whatsoever. <br />
            <span className="font-bold">"Terms"</span> means these terms and
            conditions.
            <br />
            <span className="font-bold">"Service"</span> means the website
            including all pages, subpages, all blogs, forums and other connected
            Internet content whatsoever.
            <br />
            <span className="font-bold">"Services"</span> means the services
            offered on the Website.
            <br />
            <span className="font-bold">"You, Your(s)"</span> means the user of
            this Website.
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">2. INTRODUCTION</h1>
          <p className="text-primary font-font2 text-justify">
            This Terms of Use site is available for information purposes
            relating to your engagement with Swifta Tech Talent before, during
            and after your working relationship with us.
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            3. YOUR ACCEPTANCE
          </h1>
          <p className="text-primary font-font2 text-justify">
            These Terms of Use (hereinafter “YOU”) govern your use of Swifta
            Tech Talent’s website each time you visit and/or use the service,
            you agree to be bound by these YOU. If you do not want or do not
            agree to have these terms apply to you, immediately stop using our
            site:
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            4. THIRD PARTIES SITE
          </h1>
          <p className="text-primary font-font2 text-justify">
            We may utilise third party service providers, from time to time to
            help in processing your personal data. We share your personal data
            with third parties in order to protect our rights, properties, and
            safety and for the safety of users of this website. You agree that
            Swifta Tech Talent shall not be responsible for any loss, damage, or
            liability of any kind relating to your dealings with such
            3rd-parties.
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">5. PRIVACY</h1>
          <p className="text-primary font-font2 text-justify">
            We are committed to protecting your privacy and will only use your
            personal information collected or processed in accordance with the
            applicable data protection legislation, including the Nigeria Data
            Protection Regulation (NDPR) 2019 or any applicable regulation and
            the General Data Protection Regulation. Our privacy policy also
            explains how we treat your personal data and protect your privacy
            when you use our service.
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">6. COOKIES</h1>
          <div className="text-primary font-font2 text-justify">
            We use the data collected by the cookies to offer you the best
            experience on our website. Cookies are information stored on your
            browser when you visit our website or use a social network with your
            PC, smartphone or tablet. They contain various data which includes
            the name of the server from which it originates, the numeric
            identifier, etc. The types of cookies we use are as follows:
            <ul className="list-disc pl-8">
              <li>
                Technical cookies: These cookies are essential for the correct
                functioning of our website and they are required to provide the
                service required to our users.
              </li>
              <li>
                Third party cookies: While using our service, you may receive
                cookies from websites managed by other organisations. Third
                party analytical cookies may also be installed. Third party
                analytical cookies are used to detect information on user
                behaviour on our website. This is placed in order to monitor the
                performance and improve the usability of this website.
              </li>
              <li>
                Support in configuring your browser: You can manage these
                cookies through the settings of your browser on your device.
                However, deleting cookies from your browser may remove the
                preferences you have set for this website.
              </li>
              <li>
                Log Data: We also use log files which store automatic
                information collected when users visit this:
                <ul className="list-[circle] pl-5">
                  <li>Website.</li>
                  <li>
                    The log data which may be collected are as follows:
                    <ul className="pl-5">
                      <li>
                        I. the domain and host server from which you access the
                        website;
                      </li>
                      <li>II. name of the Internet Service Provider (ISP);</li>
                      <li>III. date and time of visit;</li>
                      <li>
                        IV. your computer operating system and browser software;
                      </li>
                      <li>
                        V. web pages visited, the duration, and frequency of
                        visits;
                      </li>
                      <li>VI. your Internet Protocol (IP) address.</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <br />
          <h1 className="text-primary font-font2 font-bold">
            7. CHANGES TO THIS TERMS OF USE
          </h1>
          <p className="text-primary font-font2 text-justify">
            We reserve the right to alter this YOU at any time. Such alterations
            will be posted on our website.
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            8. DISCLAIMER OF WARRANTIES
          </h1>
          <div className="text-primary font-font2 text-justify">
            To the extent permitted by applicable law, we disclaim warranties
            and conditions and provide the service “As Is”. Using this site,
            Swifta Tech Talent;
            <ul className="pl-8">
              <li>
                a. does not guarantee that you will be provided any employment,
                job offers, or call backs through this site.
              </li>
              <li>
                b. will not be responsible for any listings, submissions, or
                request information from you.
              </li>
              <li>c. does not imply that it is your employer or your agent.</li>
              <li>
                d. makes no warranty about the suitability, reliability,
                availability, timeliness and accuracy of the information,
                contents,services and other materials contained herein for any
                purpose. We hereby disclaims all warranties and conditions with
                regard to the information, software, products, related graphics
                and materials, including all implied warranties or conditions of
                merchantability, fitness for a particular purpose, title, and
                non-infringement.
              </li>
            </ul>
          </div>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            9. ELECTRONIC COMMUNICATION
          </h1>
          <p className="text-primary font-font2 text-justify">
            You consent to receive electronic communications and you agree that
            all agreements, notices, disclosures and other communications we
            provide to you via email and on this website, satisfies any legal
            requirements that communications must be in writing.
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            10. GOVERNING LAW & JURISDICTION
          </h1>
          <p className="text-primary font-font2 text-justify">
            The Terms of Use are governed and will be construed in accordance
            with the laws of the Federal Republic of Nigeria, and the courts
            within the Federal Republic of Nigeria within shall have exclusive
            jurisdiction in all matters relating to the Terms of Use.
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            11. INDEMNIFICATION
          </h1>
          <p className="text-primary font-font2 text-justify">
            You hereby agree to indemnify the Swifta Tech Talent, its employees,
            agents; and third parties from and against all liabilities cost,
            demands cause of action, damages; and expenses (including reasonable
            attorney's fees) arising out of your use or inability to use this
            site, your violation of any rights of a third party and your
            violation of applicable laws, rules or regulation.
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            12. TERMINATION/RESTRICTION OF ACCESS
          </h1>
          <div className="text-primary font-font2 text-justify">
            Swifta Tech Talent reserves the right;
            <ul className="pl-8">
              <li>
                a. at its sole discretion, terminate your access to this website
                and the related service or any part thereof at any time, for any
                reason and without notice.
              </li>
              <li>
                b. to terminate or terminate/suspend your account for violating
                the terms of this service.
              </li>
              <li>
                c. that allows you, if you register with us, to terminate this
                service at any time by issuing a prior notice to us. Once this
                is done, you will no longer be bound by the provisions of these
                terms.
              </li>
            </ul>
          </div>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            13. GENERAL PROVISIONS/MISCELLANEOUS
          </h1>
          <div className="text-primary font-font2 text-justify">
            <ul className="pl-8">
              <li>
                a. Assignment: Swifta Tech Talent shall be permitted to assign,
                transfer its rights and/or obligations under these terms.
                However, you shall not be permitted to assign, transfer any
                rights and/or obligations under these terms.
              </li>
              <li>
                b. Entire Agreement: These terms, disclaimers and any other
                agreement relating to the use of this website constitutes the
                entire agreement and shall supersede any other agreement.
              </li>
              <li>
                c.Separate Agreements: You may have other legal agreements with
                us. Those agreements are separate from these terms. These terms
                are not intended to alter, amend, revise or replace the terms of
                the other agreement.
              </li>
              <li>
                d. Severability: Any part, provision, representation or warranty
                of this YOU which is prohibited or which is held to be void or
                unenforceable shall be ineffective to the extent of such
                prohibition or unenforceability without invalidating the
                remaining provisions hereof which remaining provisions shall be
                read as far as practicable in line with the original objective
                of this YOU.
              </li>
            </ul>
          </div>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            14. SERVICE INTERRUPTIONS
          </h1>
          <p className="text-primary font-font2 text-justify">
            Swifta Tech Talent may from time to time interrupt your access or
            use of this website to perform some maintenance or emergency
            services and you agree that we shall not be held liable for any
            damage, loss which may arise thereof.
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            15. INFORMATION OR COMPLAINTS
          </h1>
          <p className="text-primary font-font2 text-justify">
            If you have any questions regarding this Terms of Use, or if you
            wish to make any comments or complaints about anything related to
            this Terms of Use, please contact us at the following email address:{" "}
            <span className="font-bold inline-block">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:info.techtalent@swifta.com?subject=ENQUIRY"
                className="flex items-center "
              >
                info.techtalent@swifta.com
              </a>
            </span>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
