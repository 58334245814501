import React from "react";
import { NavLink } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="container ">
      <div className="mt-4 px-3">
        <nav>
          <div className="max-w-[100px] md:max-w-xs">
            <NavLink to="/">
              <img src="logo.png" alt="" />
            </NavLink>
          </div>
        </nav>
        <div className="mt-6 mb-60">
          <h1 className=" text-center text-primary font-font2 font-bold text-xl md:text-3xl mb-2">
            Swifta Tech Talent Privacy Policy
          </h1>
          <p className="mb-10 text-primary font-font2 text-justify">
            Effective date: April 1, 2022 <br />
            Applicable to: www.swiftatechtalent.com
          </p>
          <h1 className="text-primary font-font2 font-bold">1. DEFINITIONS</h1>
          <p className="text-primary font-font2 text-justify">
            <span className="font-bold">"Parties”</span> means both you and the
            Swifta Tech Talent:. <br />
            <br />
            <span className="font-bold">"Personal Data"</span> means any
            personal information collected from you when you interact with us in
            relation to your work with us.
            <br />
            <span className="font-bold">"Service"</span> means the website,
            which is known as: www.swiftatechtalent.com including all pages,
            subpages, all blogs, forums and other connected Internet content
            whatsoever
            <br />
            <span className="font-bold">"Services"</span> means the services
            offered on the website. <br />
            <span className="font-bold">
              "Third Party Service Provider"
            </span>{" "}
            means a party or parties who are contracted by Swifta Tech Talent.
            <br />
            <span className="font-bold">"You, Yours"</span> means the user of
            this Website.
            <br />
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">2. INTRODUCTION</h1>
          <p className="text-primary font-font2 text-justify">
            This privacy policy is designed to explain how we may use personal
            information we collect before, during and after your working
            relationship with us. <br />
            <br />
            We are committed to protecting your Personal Data while you use this
            website. By continuing to use our website, you acknowledge that you
            have reviewed the Privacy Policy and agree to its terms. This also
            means that you have consented to the use of your Personal Data and
            have accepted the applicable disclosures.
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            3. PERSONAL DATA/ INFORMATION WE COLLECT
          </h1>
          <div className="text-primary font-font2 text-justify">
            We collect various information when you interact with us in relation
            to your work/engagement with us. Personal information that you may
            provide to us or we may obtain from you are as follows:
            <br />
            <ul className="list-[circle] pl-8">
              <li>
                personal contact details such as name, title, addresses,
                telephone numbers, and personal email addresses;
              </li>
              <li>date of birth;</li>
              <li>gender;</li>
              <li>marital status and dependants;</li>
              <li>next of kin and emergency contacts;</li>
              <li>salary expectation</li>
              <li>start date;</li>
              <li>location of employment or workplace;</li>
              <li>
                recruitment (including copies of right to work documentation,
                references and other information included in a CV or cover
                letter or as part of the application process);
              </li>
              <li>
                employment records (including job titles, work history, working
                hours, training records and professional memberships);
              </li>
              <li>compensation history;</li>
              <li>image in photographic form;</li>
            </ul>
          </div>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            4. PURPOSE OF PROCESSING PERSONAL DATA
          </h1>
          <div className="text-primary font-font2 text-justify">
            {" "}
            We are committed to protecting your privacy and will only use your
            personal information in accordance with applicable data protection
            legislation, including the Nigeria Data Protection Regulation (NDPR)
            2019 or any applicable regulation and the General Data Protection
            Regulation. Most commonly, we will use your personal information in
            the following circumstances:
            <ul className="list-[circle] pl-8">
              <li>
                a. making a decision about your recruitment or appointment;
              </li>
              <li>
                b. where we need to perform the contract we have entered into
                with you;
              </li>
              <li>
                c. to provide personalised Service to you, including making
                recommendations and providing personalized content;
              </li>
              <li>d. where we need to comply with a legal obligation; and</li>
              <li>
                e. where it is necessary for our legitimate interests (or those
                of a third party) and your interests and fundamental rights do
                not override those interests;
              </li>
              <li>f. determining the terms on which you work for us;</li>
              <li>
                g. checking you are legally entitled to work in your assignment
                location;
              </li>
              <li>
                h. providing benefits of employment, including flexible
                benefits;
              </li>
              <li>
                i. administering the contract we have entered into with you;
              </li>
              <li>j. making decisions about salary and compensation;</li>
              <li>
                k. assessing qualifications for a particular job or task,
                including decisions about promotions;
              </li>
              <li>
                l. making decisions about your continued employment or
                engagement;
              </li>
              <li>
                m. making arrangements for the termination of our working or
                engagement relationship;
              </li>
              <li>n. education, training and development requirements;</li>
              <li>
                o. dealing with legal disputes involving you, or other
                employees, workers, employer, and contractors, including
                accidents at work;
              </li>
              <li>p. ascertaining your fitness to work;</li>
              <li>q. to prevent fraud;</li>
              <li>
                r. to monitor your use of our information and communication
                systems to ensure compliance with our IT policies.
              </li>
            </ul>
          </div>
          <br />
          <h1 className="text-primary font-font2 font-bold">5. YOUR RIGHTS</h1>
          <div className="text-primary font-font2 text-justify">
            Your rights in relation to Your Personal Data are as follows:
            <ul className="list-[circle] text-justify pl-8">
              <li>the right to have access to Your Personal Data;</li>
              <li>
                the right to be informed about the processing of Your Personal
                Data;
              </li>
              <li>
                the right to rectify any inaccurate Personal Data or any
                information about You;
              </li>
              <li>
                the right to review, modify or erase Your Personal Data and any
                other information we have about You;
              </li>
              <li>
                the right to restrict the processing of Your Personal Data;
              </li>
              <li>
                the right to block Personal Data processing in violation of any
                law;
              </li>
              <li>
                the right to be informed about any rectification or erasure of
                Personal Data or restriction of any processing carried out;
              </li>
              <li>the right to the portability of Your Personal Data; and</li>
              <li>
                the right to lodge a complaint to a supervisory authority within
                Nigeria.
              </li>
            </ul>
          </div>
          <br />
          <h1 className="text-primary font-font2 font-bold">6. COOKIES</h1>
          <div className="text-primary font-font2 text-justify">
            We use the data collected by the cookies to offer you the best
            experience on our website. Cookies are information stored on your
            browser when you visit our website or use a social network with Your
            PC, Smartphone or Tablets. They contain various data which includes
            the name of the server from which it comes, the numeric identifier,
            etc. The types of cookies we use are as follows:
            <ul className="list-disc pl-8">
              <li>
                Technical cookies: These cookies are essential for the correct
                functioning of our website and they are required to provide the
                Service required to our users.
              </li>
              <li>
                Third party cookies: While using our Service, You may receive
                cookies from websites managed by other organizations. Third
                party analytical cookies may also be installed. Third party
                analytical cookies are used to detect information on user
                behaviour on our website. This is placed in order to monitor the
                performance and improve the usability of this website.
              </li>
              <li>
                Support in configuring your browser: You can manage these
                cookies through the settings of your browser on your device.
                However, deleting cookies from your browser may remove the
                preferences you have set for this website.
              </li>
              <li>
                Log Data: We also use log files which store automatic
                information collected when users visit this
                <ul className="list-[circle] pl-5">
                  <li>Website.</li>
                  <li>
                    The log data which may be collected are as follows:
                    <ul className="pl-5">
                      <li>
                        I. the domain and host from which You access the
                        Website;
                      </li>
                      <li>II. name of the Internet Service Provider (ISP);</li>
                      <li>III. date and time of visit;</li>
                      <li>
                        IV. your computer operating system and browser software;
                      </li>
                      <li>
                        V. web pages visited, the duration, and frequency of
                        visits;
                      </li>
                      <li>VI. your Internet Protocol (IP) address.</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <br />
          <h1 className="text-primary font-font2 font-bold">
            7. THIRD PARTIES
          </h1>
          <p className="text-primary font-font2 text-justify">
            We may utilise third party service providers, from time to time to
            help in processing Your Personal Data. We share Your Personal Data
            with third parties in order to protect our rights, properties, and
            safety and for the safety of users of this website.
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            8. DATA SECURITY
          </h1>
          <p className="text-primary font-font2 text-justify">
            Our Service is built with strong security features that continuously
            protects Your Personal Data. Our security features help us detect
            and block security threats. If we detect any security risk, we may
            inform you and guide you through steps to stay protected.
            <br />
            We use the following security features to protect Your Personal Data
            from any security breach or harm:
            <br />
            <br />
            <li>
              Secure Sockets Layer (SSL) software, which encrypts information
              you input. User data shared with Swifta Tech Talent
              marketing/sales and customer service teams is confidential since
              we have a Non-Disclosure Agreement with all our staff, client and
              other third parties.
            </li>
            <li>
              You should take all reasonable steps to keep your personal
              information held on our IT systems secure, including choosing a
              secure password for your accounts and not disclosing your
              passwords to anybody else. You should use a unique password for
              every account. Further details are in our IT and password
              policies.
            </li>
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            9. RETENTION, UPDATING AND REMOVAL OF YOUR PERSONAL INFORMATION
          </h1>
          <p className="text-primary font-font2 text-justify">
            The duration for which we retain your personal information will
            differ depending on the type of information and the reason why we
            collected it from you. However, in some cases personal information
            may be retained on a long term basis. For example, personal
            information that we need to retain for legal purposes will normally
            be retained for at least five years in accordance with usual
            commercial practice and regulatory requirements. It is important to
            ensure that the personal information we hold about you is accurate
            and up-to-date, and you should let us know if anything changes. For
            example, if you move home or change your phone number or email
            address. You may be able to update some of the personal information
            we hold about you by contacting us.
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            10. DISCLOSURE OF YOUR PERSONAL INFORMATION
          </h1>
          <div className="text-primary font-font2 text-justify">
            We may share your personal information where it is necessary to
            administer the working relationship or we have a legitimate interest
            in doing so. We may also disclose your personal information to third
            parties if we are under a duty to disclose or share it in order to
            comply with any legal obligation, enforce or apply such other terms
            as apply to our relationship, or to protect rights, property, or
            safety of our other employees, workers and contractors, our
            customers, ourselves or others with you or where we have a
            legitimate interest in doing so. This includes exchanging
            information with other companies and organisations for the purposes
            of providing references and fraud protection.
            <ul className="list-[circle] pl-8">
              <li>
                ”Third parties” includes third-party service providers
                (including contractors and designated agents) and other Swifta
                Tech Talent’s partners.
              </li>
              <li>
                The third parties we share your personal information with where
                required by law are courts and governmental agencies.
              </li>
              <li>
                The third parties we share your personal information with where
                it is necessary to administer the working relationship with you
                include (where you are a contractor) the client of ours for whom
                you are ultimately providing services to.
              </li>
              <li>
                The following activities are carried out by third-party service
                providers: payroll, pension administration, benefits provision
                and administration, identity verification, criminal record check
                and IT services. All our third-party service providers and other
                entities in the group are required to take appropriate security
                measures to protect your personal information in line with our
                policies. We do not allow our third-party service providers to
                use your personal data for their own purposes. We only permit
                them to process your personal data for specified purposes and in
                accordance with our instructions.
              </li>
              <li>
                We may also need to share your personal information with a
                regulator or to otherwise comply with the law.
              </li>
              <li>
                In certain cases, the disclosure of your personal information to
                a third party as described in this section10 may involve your
                personal information being transferred outside of the Federal
                Republic of Nigeria. This may be to:
                <ul className="pl-5">
                  <li>
                    a. a country considered to have data protection rules that
                    are equivalent to those in the Federal Republic of Nigeria;
                    or
                  </li>
                  <li>
                    b. a country which is not considered to have the same
                    standards of protection for personal data as those in the
                    Federal Republic of Nigeria, in which case we will take all
                    steps required by law to ensure sufficient protections are
                    in place to safeguard your personal information, including
                    where appropriate putting in place contractual terms
                    approved by the relevant regulatory authorities.
                  </li>
                </ul>
              </li>
              <li>
                For more information about the circumstances in which your
                personal information may be disclosed to third parties and the
                safeguards we put in place to protect your personal information
                when we do so, please contact us.
              </li>
            </ul>
          </div>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            11. CHANGES TO THIS PRIVACY NOTICE
          </h1>
          <p className="text-primary font-font2 text-justify">
            We reserve the right to alter this privacy notice at any time. Such
            alterations will be posted on our website. Should you object to any
            alteration, please contact us.
          </p>
          <br />
          <h1 className="text-primary font-font2 font-bold">
            12. CONTACTING US
          </h1>
          <p className="text-primary font-font2 text-justify">
            If you have any questions regarding this Privacy Policy or the
            Personal Data we collect, or if you wish to make any comments or
            complaints about anything related to this Privacy Policy, please
            contact us at the following email address:{" "}
            <span className="font-bold inline-block">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:info.techtalent@swifta.com?subject=ENQUIRY"
                className="flex items-center "
              >
                info.techtalent@swifta.com
              </a>
            </span>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
