import React from "react";
import { talents } from "./shared";

const TalentPool = () => {
  return (
    <div className="container" id="TalentPool">
      <div className="flex flex-col ">
        <div className="small-title text-center w-full">Seasoned experts</div>
        <div className="large-title text-center w-full">Our talent pool</div>
        <p className="text-center max-w-[744px] mx-auto font-font1">
          Want to design and develop a product, set up your tech infrastructure,
          or fill up tech roles in your organization? We've got you! Our diverse
          resource pool boasts skill sets relevant to your specific
          requirements.
        </p>
        <div className="flex flex-wrap justify-center mt-4">
          {talents.map((talent, idx) => (
            <div
              key={idx}
              className="mx-2 my-[10px] md:mx-[41px] md:my-[15px] w-[156px] h-[149px] shadow-talentPool hover:shadow-talentPoolHover transition-all rounded-[20px] flex flex-col justify-center items-center  p-3  md:w-[203px] md:h-[194px] md:px-10 md:py-6"
            >
              <div className="w-[60px] h-[60px] rounded-full shadow-talentPoolImg flex items-center justify-center mb-2">
                <img src={talent.image} alt={talent.alt} />
              </div>
              <p className="text-xl font-font1 text-center font-semibold text-primary ">
                {talent.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TalentPool;
