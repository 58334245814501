import React from "react";
import Form from "./Form";

const HireTalent = () => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="bg-hireTalent h-[51px] bg-no-repeat bg-cover bg-right-top md:h-screen md:w-1/2  overflow-hidden container">
        <div className="w-[90%] mx-auto md:mt-7 mt-2">
          <div className=" left-4 md:top-12 md:left-16 mobile:w-[109px] md:w-[170px] flex justify-start">
            <img src="placeholderWhite.svg" alt="Logo" className="w-full" />
          </div>
        </div>
        <div className="mt-3 md:mt-[48px] max-w-[280px] h-[280px] bg-white rounded-xl md:max-w-[500px] md:h-[500px] mx-auto flex items-center justify-center mobile:hidden">
          <div className="max-w-[250px] md:max-w-[400px]">
            <lottie-player
              src="recruitment.json"
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </div>
        </div>
      </div>
      <Form />
    </div>
  );
};

export default HireTalent;
