import React, { useState } from "react";
import { testimonies } from "./shared";
// import { testimonialImgs } from "./shared";

const Testimonials = () => {
  const [current, setCurrent] = useState(0);
  const length = testimonies.length;

  // let Id = 0;
  let middle =
    "left-1/2 top-1/2 -translate-x-[60%] -translate-y-1/2 md:max-w-[126px] max-w-[80px] absolute";
  // let mid = "left-[9%] bottom-1/3 max-w-[54px] absolute";

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  return (
    <div
      className="bg-testimonial bg-no-repeat bg-cover bg-left-top  py-10 md:py-[72px]"
      id="Testimonials"
    >
      <div className="container flex flex-col md:flex-row-reverse items-center justify-between ">
        <div className="w-1/2 md:max-w-[465px] min-w-[250px] relative flex mobile:mb-[28px] md:ml-14">
          <img src="testimony.svg" alt="" className="w-full " />
          <div className="absolute right-2 top-2 md:right-6 md:top-6 -z-10">
            <lottie-player
              src="if30_editor_5amvxc6m.json"
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </div>
          <div>
            {testimonies.map((testimony, idx) => (
              <div key={idx}>
                {/* {idx === current && ( */}
                <div
                  className={` ${
                    current === testimony.id ? middle : testimony.imageLocation
                  }`}
                >
                  <img src={testimony.image} alt="" />
                  {/* {console.log(testimony.image)} */}
                </div>
                {/* )} */}
              </div>
            ))}
          </div>
        </div>
        {/* {console.log(testimonialImgs[current])}
          <div> */}
        {/* {testimonialImgs.map((testimony, idx) => {
              
              // let position1 =
              //   "left-1/2 top-1/2 -translate-x-[60%] -translate-y-1/2 max-w-[126px]";
              // let position2 =
              //   "left-1/2 -translate-x-[60%] translate-y-1/2 max-w-[84px]";
              // let position3 = "right-0 top-[20%] max-w-[84px]";
              // let position4 = "left-[9%] bottom-1/3 max-w-[54px]";
              // let position5 = "left-[58%]  -bottom-[5%] max-w-[99px]";
              // let position = "";
              // if (idx === 0) {
              //   position = "right-0 top-[20%] max-w-[84px]";
              //   if (current === idx) {
              //     position =
              //       "left-1/2 top-1/2 -translate-x-[60%] -translate-y-1/2 max-w-[126px]";
              //   }
              //   if (
              //     idx === current - 1 ||
              //     (current === 0 && idx === testimonialImgs.length - 1)
              //   ) {
              //     position = "left-[58%]  -bottom-[5%] max-w-[99px]";
              //   }
              // }
              // if (
              //   current + 1 === idx ||
              //   (current + 1 === 0 && idx === testimonies.length - 2)
              // ) {
              //   position =
              //     "left-1/2 -translate-x-[60%] translate-y-1/2 max-w-[84px]";
              // }
              // if (
              //   idx === current - 2 ||
              //   (current === 0 && idx === testimonies.length - 2)
              // ) {
              //   position = "right-0 top-[20%] max-w-[84px] ";
              // }
              // if (
              //   idx === current - 3 ||
              //   (current === 0 && idx === testimonies.length - 3)
              // ) {
              //   position = "left-[9%] bottom-1/3 max-w-[54px]";
              // }
              // if (
              //   idx === current - 4 ||
              //   (current === 0 && idx === testimonies.length - 4)
              // ) {
              //   position = "left-[58%]  -bottom-[5%] max-w-[99px]";
              // }
              return (
                <div className={` absolute z-10   ${idx}`}>
                  <img src={testimony} alt="" />
                </div>
              );
            })} */}

        {/* <div
              className={` absolute z-10 ${"left-1/2 -translate-x-[60%] translate-y-1/2 max-w-[84px]"}`}
            >
              <img src={testimonialImgs[0]} alt="" />
            </div>

            <div className="max-w-[84px] absolute z-10 right-0 top-[20%]">
              <img src={testimonialImgs[0]} alt="" />
            </div>

            <div className="max-w-[54px] absolute z-10 left-[9%] bottom-1/3">
              <img src={testimonialImgs[0]} alt="" />
            </div>

            <div className="max-w-[99px] absolute z-10 left-[58%]  -bottom-[5%]">
              <img src={testimonialImgs[0]} alt="" />
            </div> */}
        {/* </div> */}

        {/*<div className="max-w-[126px] absolute z-10 left-1/2 top-1/2 -translate-x-[60%] -translate-y-1/2 ">
            <img
              src={testimonialImgs[current === length - 1 ? 0 : current + 1]}
              alt=""
            />
          </div>

          <div className="max-w-[84px] absolute z-10 left-1/2 -translate-x-[60%] translate-y-1/2">
            <img src={testimonialImgs[current + 1]} alt="" />
          </div>

          <div className="max-w-[84px] absolute z-10 right-0 top-[20%]">
            <img
              src={testimonialImgs[current === length - 1 ? 0 : current + 2]}
              alt=""
            />
          </div>

          <div className="max-w-[54px] absolute z-10 left-[9%] bottom-1/3">
            <img
              src={testimonialImgs[current === length - 1 ? 0 : current + 3]}
              alt=""
            />
          </div>

          <div className="max-w-[99px] absolute z-10 left-[58%]  -bottom-[5%]">
            <img
              src={testimonialImgs[current === length - 1 ? 0 : current + 4]}
              alt=""
            />
          </div> */}

        <div className="md:w-1/2 md:max-w-[364px]">
          <h1 className="font-font2 font-medium text-secondary text-2xl md:text-4xl mb-[10px] md:mb-[14px]">
            Take a look at what our users think of us
          </h1>
          <div>
            {testimonies.map((testimony, idx) => (
              <div key={idx}>
                {idx === current && (
                  <p className="font-font1 font-normal text-secondary text-base">
                    "{testimony.content}"
                  </p>
                )}
              </div>
            ))}
            <div className="flex items-end justify-between mt-5 md:mt-10">
              <div>
                {testimonies.map((testimony, idx) => (
                  <div key={idx}>
                    {idx === current && (
                      <div>
                        <p className="font-font1 text-xl text-secondary mb-1 font-semibold">
                          {testimony.name}
                        </p>
                        <p
                          className="font-font1 text-base text-secondary font-medium"
                          dangerouslySetInnerHTML={testimony.position}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="flex">
                <div onClick={prevSlide} className="cursor-pointer mr-5 w-9">
                  <img src="left.svg" alt="" />
                </div>
                <div onClick={nextSlide} className="cursor-pointer w-9">
                  <img src="left.svg" alt="" className="rotate-180 " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
