export const Services = [
  {
    image: "recruitment.svg",
    title: "Recruitment",
    content1: {
      __html:
        "We handle the entire process from screening to integration into your company with our experienced recruiters, who are adept at matching the right talent to fit your business tech needs.",
    },
    content2: {
      __html:
        "We help you fast-track the hiring process and update your database with the latest job profiles.",
    },
    features: [
      "Pre-screened candidates",
      "Validated skills",
      "Reduced time-to-hire",
      "Smooth onboarding",
    ],
    button: "Start hiring",
  },
  {
    image: "recuitment.svg",
    title: "Outsourcing",
    content1: {
      __html:
        "Keep your eyes on the big picture while we focus on the resource coordination, communication, budget control, risk management and update reporting of your projects.",
    },
    content2: {
      __html:
        "We help liberate your business from time-consuming functions you lack resources for.",
    },
    features: [
      "Lower cost",
      "Experienced resources",
      "Increased efficiency",
      "Accelerated time-to-market",
    ],
    button: "Let's outsource",
  },
  {
    image: "placement.svg",
    title: "Placement",
    content1: {
      __html:
        "We match your talent to companies' requirements by extracting your verified and updated skills into a tech talent profile that makes you discoverable and highly marketable.",
    },
    content2: {
      __html:
        "Boost your chances of being shortlisted and landing that dream job by joining our talent pool.",
    },
    features: ["Top brands", "Big project", "Free marketing", "Career growth"],
    button: "I'm in",
  },
];
