import React from "react";

const Button = (props) => {
  const name = props.name;
  const filled = props.filled;
  return (
    <button
      className={`bg-white text-secondary border border-solid
       border-border rounded-[26px] h-[50px] w-[156px] md:w-[174px] hover:bg-secondary 
       hover:text-white transition-all font-font1 font-semibold text-base md:text-lg
       ${
         filled &&
         "mobile:bg-secondary mobile:hover:bg-white mobile:hover:text-secondary mobile:text-white"
       }
       `}
    >
      {name}
    </button>
  );
};

export default Button;
