import React, { useState, useEffect, useRef } from "react";
import Button from "../Button/Button";
import { GoPrimitiveDot } from "react-icons/go";
import { IconContext } from "react-icons";
import { animateScroll as scroll } from "react-scroll";
import { NavLink } from "react-router-dom";
import Typed from "typed.js";

const Hero = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  // Create reference to store the DOM element containing the animation
  const el = useRef(null);
  // Create reference to store the Typed instance itself
  const typed = useRef(null);

  useEffect(() => {
    const options = {
      strings: ["business", "project", "product", "service"],
      loop: true,
      loopCount: Infinity,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 5000,
      autoInsertCss: false,
    };

    // elRef refers to the <span> rendered below
    typed.current = new Typed(el.current, options);

    return () => {
      // Make sure to destroy Typed instance during cleanup
      // to prevent memory leaks
      typed.current.destroy();
    };
  }, []);
  return (
    <div className="hero-container flex justify-between flex-col md:flex-row  md:items-center  md:pb-[71px] pb-11 ">
      <div className="flex flex-col pt-5 mr-2 mb-2 ">
        <div className="small-title">
          The largest pool of tech resources is a few clicks away!
        </div>
        <hero-title>
          Onboard plug-n-play talent for your{" "}
          <br className="small:hidden smaller:block" />
          <span style={{ whiteSpace: "pre" }} ref={el} />
        </hero-title>
        <div className="flex flex-wrap  mt-1 items-center font-font1 ">
          <div className="flex  items-center mr-[18px] mb-3">
            <img src="quality.svg" alt="Quality Img" className="mr-2" />
            <p className="mobile:text-[14px] text-primary font-medium">
              Vetted talent
            </p>
          </div>
          <div className="mr-[18px] mb-3">
            <IconContext.Provider value={{ color: "#0E244B" }}>
              <GoPrimitiveDot />
            </IconContext.Provider>
          </div>
          <div className="flex items-center  mr-[18px] mb-3">
            <img src="fast.svg" alt="Fast Img" className="mr-2" />
            <p className="mobile:text-[14px] text-primary font-medium">
              Faster hiring
            </p>
          </div>
          <div className="mr-[18px] mb-3">
            <IconContext.Provider value={{ color: "#0E244B" }}>
              <GoPrimitiveDot />
            </IconContext.Provider>
          </div>
          <div className="flex  items-center mr-[18px] mb-3">
            <img src="timelapse.svg" alt="Time Img" className="mr-2" />
            <p className="mobile:text-[14px] text-primary font-medium">
              Timely delivery
            </p>
          </div>
        </div>
        <div className="flex  mt-7 flex-wrap">
          <div className="mr-4 mb-4">
            <NavLink to="/hire-talent">
              <Button name="Hire the right fit" filled />
            </NavLink>
          </div>
          <a
            href="https://forms.gle/zbvE8T4tqQHiJa6x6"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button name="Join the pool" />
          </a>
        </div>
      </div>
      {/* <div className=""> */}
      <img
        src="hero-image.jpg"
        alt=""
        className="flex self-center justify-self-center mx-auto md:max-w-[632px] mobile:max-w-[344px]"
      />
      {/* </div> */}
      {showTopBtn && (
        <div
          className="fixed bottom-4 right-4 z-50"
          onClick={() => scroll.scrollToTop()}
        >
          <div className="group w-[60px] h-16 md:w-20 md:h-[85px] flex items-center justify-center rounded-t-xl shadow-arrow  bg-white cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 22.507 28.034"
              className="w-4 md:w-7 fill-arrow group-hover:fill-secondary"
            >
              <path
                d="M12.445,17.979l9.563,9.563a1.681,1.681,0,0,1,0,2.384l-1.589,1.589a1.681,1.681,0,0,1-2.384,0L11.25,24.743,4.472,31.521a1.681,1.681,0,0,1-2.384,0l-1.6-1.589a1.681,1.681,0,0,1,0-2.384l9.563-9.562A1.683,1.683,0,0,1,12.445,17.979Zm-2.391-13.5L.492,14.041a1.681,1.681,0,0,0,0,2.384l1.589,1.589a1.681,1.681,0,0,0,2.384,0l6.778-6.778,6.778,6.778a1.681,1.681,0,0,0,2.384,0l1.589-1.589a1.681,1.681,0,0,0,0-2.384L12.431,4.479a1.672,1.672,0,0,0-2.377,0Z"
                transform="translate(0.004 -3.983)"
              />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
