import React from "react";
import { services } from "./shared";
import Button from "../Button/Button";
import { Link } from "react-scroll";

const Services = () => {
  return (
    <div className="container flex flex-col mt-11 " id="Services">
      <div className="mb-[50px] md:mb-[75px]">
        <div className="small-title">What we offer</div>
        <div className="large-title">Our services</div>
        <div className="flex mobile:flex-col   mt-5 ">
          {services.map((service, idx) => (
            <div
              className={`flex flex-col  font-font1 basis-0 grow justify-between  ${
                idx !== 2 && "mr-11 mobile:mb-5"
              }`}
              key={idx}
            >
              <div className="h-20 mb-2">
                <img src={service.image} alt="" className="h-20" />
              </div>
              <h1 className="text-xl font-semibold text-primary  mb-2 font-font1">
                {service.title}
              </h1>
              <p
                className="text-base text-primary mb-2 text-justify"
                dangerouslySetInnerHTML={service.content}
              ></p>
              <Link className=" mb-2" to={service.title} smooth duration={1000}>
                <Button name="Learn more" height />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
