import React, { useState } from "react";
import { Link } from "react-scroll";

const NavBar = () => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };
  return (
    <>
      <nav className="sticky w-full h-16 shadow-nav z-30 ">
        <div className="relative max-w-[1230px] my-0 mx-auto">
          <div className=" flex  w-full pl-4 pr-4 bg-white ">
            <div className="h-16 w-full  bg-white  flex items-center justify-between ">
              <div className="">
                <img src="Placeholder.svg" className="h-8 " alt="Logo" />
              </div>

              <div
                className="relative flex justify-center items-center w-8 h-8 cursor-pointer md:hidden"
                onClick={handleToggle}
              >
                <div
                  className={`
                    ${
                      !toggle
                        ? "w-7 h-1 bg-primary rounded-full before:content-[''] after:content-[''] before:absolute after:absolute before:rounded-full after:rounded-full before:w-7  after:w-7 before:h-1 after:h-1 before:-translate-y-2.5 after:translate-y-2.5 before:bg-primary after:bg-primary before: transition after:transition before: ease-in-out after:ease-in-out before:duration-500	after:duration-500	"
                        : "w-7 h-1 bg-transparent rounded-full before:content-[''] after:content-[''] before:absolute after:absolute before:rounded-full after:rounded-full before:w-7  after:w-7 before:h-1 after:h-1 before:bg-primary after:bg-primary before:rotate-45  after:-rotate-45 transition after:transition before: ease-in-out after:ease-in-out before:duration-500	after:duration-500"
                    }`}
                ></div>
              </div>
              <div
                className={`
                  ${
                    toggle
                      ? "mobile:absolute mobile:w-full  mobile:bg-white mobile:top-16 mobile:right-0 mobile:transition-all mobile:ease-in-out mobile:duration-700 mobile:-z-10 mobile:shadow-nav mobile:border-t mobile:border-opacity-30  mobile:border-solid"
                      : "mobile:absolute mobile:w-full  mobile:bg-white mobile:-top-64 mobile:right-0 mobile:transition-all mobile:ease-in-out mobile:duration-500 mobile:-z-10 mobile:shadow-nav"
                  }`}
              >
                <ul className="mobile:bg-white  mobile:h-full mobile:flex mobile:flex-col  mobile:justify-center mobile:p-6 mobile:shadow-nav  md:h-16 md:flex md:items-center md:pt-0 md:z-20 text-sm">
                  <li className="mobile:mb-6 cursor-pointer font-font2 font-medium text-primary md:mr-8">
                    <Link
                      to="Services"
                      onClick={handleToggle}
                      smooth
                      duration={1000}
                    >
                      Services
                    </Link>
                  </li>
                  <li className="mobile:mb-6 cursor-pointer font-font2 font-medium text-primary md:mr-8">
                    <Link
                      to="TalentPool"
                      onClick={handleToggle}
                      smooth
                      duration={1000}
                    >
                      Talent pool
                    </Link>
                  </li>
                  <li className=" cursor-pointer font-font2 font-medium text-primary">
                    <Link
                      to="Testimonials"
                      onClick={handleToggle}
                      smooth
                      duration={1000}
                    >
                      Testimonials
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
