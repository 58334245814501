import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
} from "react-icons/fa";
import { BiEnvelope } from "react-icons/bi";
import { FiPhoneCall } from "react-icons/fi";
import { IconContext } from "react-icons";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="container">
      <div className="flex flex-col mt-[50px] md:mt-[100px] mb-[18px] ">
        <div className="mb-[22px] md:mb-[45px] flex justify-between flex-wrap ">
          <div className="flex flex-col mr-10 mb-10">
            <div className="w-[170px] mb-5">
              <NavLink to="/">
                <img src="Placeholder.svg" alt="" className="w-[170px]" />
              </NavLink>
            </div>
            <p className="mb-5">
              6b Crown court estate, Oniru,
              <br /> Victoria Island, lagos
            </p>
            <div className="flex  flex-wrap">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://web.facebook.com/swiftasystem/"
                className="mr-4 mb-2"
              >
                <div className="flex justify-center items-center h-10 w-10 rounded-full border border-solid border-border">
                  <IconContext.Provider value={{ color: "#3B78E7" }}>
                    <div>
                      <FaFacebookF />
                    </div>
                  </IconContext.Provider>
                </div>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/swiftasystems"
                className="mr-4 mb-2"
              >
                <div className="flex justify-center items-center h-10 w-10 rounded-full border border-solid border-border">
                  <IconContext.Provider value={{ color: "#3B78E7" }}>
                    <div>
                      <FaTwitter />
                    </div>
                  </IconContext.Provider>
                </div>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/2725014/"
                className="mr-4 mb-2"
              >
                <div className="flex justify-center items-center h-10 w-10 rounded-full border border-solid border-border">
                  <IconContext.Provider value={{ color: "#3B78E7" }}>
                    <div>
                      <FaLinkedin />
                    </div>
                  </IconContext.Provider>
                </div>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/swiftasystems/"
              >
                <div className="flex justify-center items-center h-10 w-10 rounded-full border border-solid border-border">
                  <IconContext.Provider
                    value={{
                      color: "#3B78E7",
                    }}
                  >
                    <div>
                      <FaInstagram />
                    </div>
                  </IconContext.Provider>
                </div>
              </a>
            </div>
          </div>

          <div className="mr-10 mb-10">
            <h1 className="font-font1 font-semibold text-2xl text-primary mb-8">
              Menu
            </h1>
            <ul className="flex flex-col ">
              <li className="cursor-pointer mb-4">
                <Link to="Services" smooth duration={1000}>
                  Services
                </Link>
              </li>
              <li className="cursor-pointer mb-4">
                <Link to="TalentPool" smooth duration={1000}>
                  Talent pool
                </Link>
              </li>
              <li className="cursor-pointer">
                <Link to="Testimonials" smooth duration={1000}>
                  Testimonials
                </Link>
              </li>
            </ul>
          </div>

          <div className="mr-10 mb-10">
            <h1 className="font-font1 font-semibold text-2xl text-primary mb-8">
              Useful links
            </h1>
            <ul className="flex flex-col ">
              <li className="mb-4">
                <NavLink
                  to="/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of use
                </NavLink>
              </li>
              <li>
                {" "}
                <NavLink
                  to="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Privacy policy
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="mr-10 mb-10">
            <h1 className="font-font1 font-semibold text-2xl text-primary mb-8">
              Stay connected
            </h1>
            <ul className="flex flex-col ">
              <li className=" mb-4 flex flex-col w-fit">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="tel:+234 803 669 8180"
                  className="flex items-center"
                >
                  <div className="mr-2">
                    <IconContext.Provider
                      value={{ color: "#3B78E7", size: 20 }}
                    >
                      <FiPhoneCall />
                    </IconContext.Provider>
                  </div>
                  +234 803 669 8180
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="tel:+234 813 528 5042"
                  className="flex self-end"
                >
                  +234 813 528 5042
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:sales@swifta.com?subject=ENQUIRY"
                  className="flex items-center "
                >
                  <div className="mr-2">
                    <IconContext.Provider
                      value={{ color: "#3B78E7", size: 20 }}
                    >
                      <BiEnvelope />
                    </IconContext.Provider>
                  </div>
                  sales@swifta.com
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr className="mb-[22px] md:mb-[45px]" />
        <div className="flex justify-center md:justify-between flex-wrap ">
          <div className="flex items-center mr-4 mb-4">
            <p className="text-primary font-font1 text-base mr-2">Powered by</p>
            <div className="w-[93px]">
              <img src="swifta.png" alt="" className="w-[93px]" />
            </div>
          </div>
          <p>Copyright {new Date().getFullYear()}. All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
